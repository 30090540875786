import React, { useEffect } from 'react';
import { navigate } from 'gatsby';

const BlogPage = () => {
  useEffect(() => {
    navigate('/');
  }, []);
  return <></>;
};

export default BlogPage;
